import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Seo from '../components/seo'
import { Columns, Container, Heading, Section, Element } from 'react-bulma-components'
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next'

const RGPDPage = () => {
  const { t } = useTranslation('rgpd')
  return (
    <Layout>
      <Section>
        <Container>
          <Element textAlign={'center'} mb={5}>
            <Heading size={4}>{t('title')}</Heading>
          </Element>
          <Element mb={6}>
            <Heading size={5}>{t('p_1_title')}</Heading>
            <Element renderAs='p'>{t('p_1_content')}</Element>
          </Element>
          <Element mb={6}>
            <Heading size={5}>{t('p_2_title')}</Heading>
            <Element renderAs='p'>{t('p_2_content')}</Element>
          </Element>
          <Element mb={6}>
            <Heading size={5}>{t('p_3_title')}</Heading>
            <Element renderAs='p'>{t('p_3_content')}</Element>
            <Element renderAs='p' textWeight='bold' mt={2} mb={2}>{t('p_3_sub_title')}</Element>
            <Element renderAs='ul' pl={6}>
              <Element renderAs='li' mb={2} style={{ listStyle: 'initial' }}>
                {t('p_3_data_1')}
              </Element>
              <Element renderAs='li' style={{ listStyle: 'initial' }}>
                {t('p_3_data_2')}
              </Element>
            </Element>
          </Element>
          <Element renderAs='p' mb={6}>
            <Heading size={5}>{t('p_4_title')}</Heading>
            <Element renderAs='p' mb={2}>{t('p_4_content_1')}</Element>
            <Element renderAs='p' mb={2}>{t('p_4_content_2')}</Element>
            <Element renderAs='p' mb={2}>{t('p_4_content_3')}</Element>
            <Element renderAs='p'>{t('p_4_content_4')}</Element>
          </Element>
          <Element mb={6}>
            <Heading size={5}>{t('p_5_title')}</Heading>
            <Element renderAs='p'>{t('p_5_content')}</Element>
          </Element>
          <Element mb={6}>
            <Heading size={5}>{t('p_6_title')}</Heading>
            <Element renderAs='p'>{t('p_6_content')}</Element>
            <Element renderAs='p' mt={2} mb={2}>{t('p_6_sub_title')}</Element>
            <Element renderAs='ul' pl={6}>
              <Element renderAs='li' style={{ listStyle: 'initial' }}>
                {t('p_6_data_1')}
              </Element>
              <Element renderAs='li' style={{ listStyle: 'initial' }}>
                {t('p_6_data_2')}
              </Element>
              <Element renderAs='li' style={{ listStyle: 'initial' }}>
                {t('p_6_data_3')}
              </Element>
              <Element renderAs='li' style={{ listStyle: 'initial' }}>
                {t('p_6_data_4')}
              </Element>
            </Element>
          </Element>
          <Element mb={6}>
            <Heading size={5}>{t('p_7_title')}</Heading>
            <Element renderAs='p'>{t('p_7_content')}</Element>
          </Element>
          <Element mb={6}>
            <Heading size={5}>{t('p_8_title')}</Heading>
            <Element renderAs='p' mb={2}>{t('p_8_content')}</Element>
            <Element renderAs='p' mb={2}>{t('p_8_content_2')}</Element>
            <Element renderAs='p'>{t('p_8_content_3')}</Element>
          </Element>
          <Element mb={6}>
            <Heading size={5}>{t('p_9_title')}</Heading>
            <Element renderAs='p' mb={2}>{t('p_9_content')}</Element>
            <Element renderAs='p'>{t('p_9_content_2')}</Element>
          </Element>
          <Element mb={6}>
            <Heading size={5}>{t('p_10_title')}</Heading>
            <Element renderAs='p' mb={2}>{t('p_10_content')}</Element>
            <Element renderAs='p'>{t('p_10_content_2')}</Element>
          </Element>
        </Container>
      </Section >
    </Layout >
  )
}

export default RGPDPage

export const query = graphql`
  query($language: String!) {
          locales: allLocale(filter: {language: {eq: $language}}) {
          edges {
          node {
          ns
          data
          language
        }
      }
    }
  }
`